import { CatalogKeys, PaintingsStatus } from '~/types/Catalog'

export const getLabel = (key: CatalogKeys, value: number | string, cities: Array<{title: string, value: string}>) => {
  switch (key) {
    case 'heightFrom':
      return `Высота от ${value}см`

    case 'heightTo':
      return `Высота до ${value}см`

    case 'widthFrom':
      return `Ширина от ${value}см`

    case 'widthTo':
      return `Ширина до ${value}см`

    case 'priceFrom':
      return `Цена от ${value}₽`

    case 'priceTo':
      return `Цена до ${value}₽`

    case 'yearFrom':
      return `Год от ${value}`

    case 'yearTo':
      return `Год до ${value}`

    case 'status':
      return PaintingsStatus[value]

    case 'city':
      return `г. ${cities.find(city => city.value === value)?.title || value}`

    default:
      return value
  }
}

export const getDefaultValue = (key: CatalogKeys, maxPrice: number) => {
  switch (key) {
    case 'heightFrom':
    case 'priceFrom':
    case 'widthFrom':
      return 0

    case 'heightTo':
    case 'widthTo':
      return 2000

    case 'city':
    case 'status':
      return ''

    case 'priceTo':
      return maxPrice

    case 'yearFrom':
      return 1900

    case 'yearTo':
      return new Date().getFullYear()
  }
}

export const isDefaultValue = (key: CatalogKeys, value: number | string, maxPrice: number) => {
  switch (key) {
    case 'heightFrom':
    case 'priceFrom':
    case 'widthFrom':
      return +value === 0

    case 'heightTo':
    case 'widthTo':
      return +value === 2000

    case 'priceTo':
      return +value === maxPrice

    case 'yearFrom':
      return +value === 1900

    case 'yearTo':
      return +value === new Date().getFullYear()

    case 'status':
    case 'city':
      return !value
  }
}
